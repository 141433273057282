*{
    box-sizing: border-box;
}

@font-face {
    font-family: Grotesque;
    font-weight: 400;
    src: url('assets/fonts/GrotesqueMTStd-Light/GrotesqueMTStd-Light.woff') format("woff"),
    url('assets/fonts/GrotesqueMTStd-Light/GrotesqueMTStd-Light.eot') format("eot")
    ;
}
@font-face {
    font-family: Grotesque;
    font-weight: 800;
    src: url('assets/fonts/GrotesqueMTStd-Bold/GrotesqueMTStd-Bold.woff') format("woff"),
    url('assets/fonts/GrotesqueMTStd-Bold/GrotesqueMTStd-Bold.eot') format("eot");
}

html,
body{
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-family: Grotesque, Helvetica, sans-serif;
}

body{
    @media screen and (min-width: 1023px) {
        display: flex;
    }
}

a{
    color: inherit;
}

img{
    max-width: 100%;
    height: auto;
    display: block;
}

.video-embed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


figure{
    margin: 1rem 0;
}

figcaption{
    margin: .25rem 0 0;
}

h1, h2, h3{
    font-weight: 800;
    font-size: 1rem;
    margin: 0;
}

* + h1,
* + h2,
* + h3{
    margin-top: 1.5rem;
}

p{
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
}

ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    &.rientro{
        padding: 0 0 0 1.5rem;
    }
}


header{
    background: white;
    @media screen and (min-width: 1023px) {
        > div{
            position: sticky;
            top: 10px;
        }
        width: 400px;  
    }
    padding: 10px;
    ul{
        padding: 0;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
        &.active{
            //font-style: italic;
            &::before{
                content: "— ";
            }
        }
    }
}

main{
    padding: 10px 10px 50px;
    max-width: 600px;
}
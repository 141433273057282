@charset "UTF-8";
* {
  box-sizing: border-box;
}
@font-face {
  font-family: Grotesque;
  font-weight: 400;
  src: url("GrotesqueMTStd-Light.841836fc.woff") format("woff"), url("GrotesqueMTStd-Light.0146816f.eot") format("eot");
}
@font-face {
  font-family: Grotesque;
  font-weight: 800;
  src: url("GrotesqueMTStd-Bold.1a85e32e.woff") format("woff"), url("GrotesqueMTStd-Bold.bb123c01.eot") format("eot");
}
html,
body {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: Grotesque, Helvetica, sans-serif;
}
@media screen and (min-width: 1023px) {
  body {
    display: flex;
  }
}
a {
  color: inherit;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
.video-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.video-embed iframe,
.video-embed object,
.video-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
figure {
  margin: 1rem 0;
}
figcaption {
  margin: 0.25rem 0 0;
}
h1, h2, h3 {
  font-weight: 800;
  font-size: 1rem;
  margin: 0;
}
* + h1,
* + h2,
* + h3 {
  margin-top: 1.5rem;
}
p {
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul.rientro {
  padding: 0 0 0 1.5rem;
}
header {
  background: white;
  padding: 10px;
}
@media screen and (min-width: 1023px) {
  header {
    width: 400px;
  }
  header > div {
    position: sticky;
    top: 10px;
  }
}
header ul {
  padding: 0;
}
header a {
  text-decoration: none;
}
header a:hover {
  text-decoration: underline;
}
header a.active::before {
  content: "— ";
}
main {
  padding: 10px 10px 50px;
  max-width: 600px;
}
/*# sourceMappingURL=index.fc91bae3.css.map */
